import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/logo.svg";
import "./navbar.scss";

const NavbarSecion = () => {
  return (
    <Navbar
      collapseOnSelect
      data-bs-theme="dark"
      expand="lg"
      fixed="top"
      className="navbar-scroll"
    >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link href="#">HOME</Nav.Link>
            <Nav.Link href="#">ABOUT US</Nav.Link>
            <Nav.Link href="#">RIDE WITH US</Nav.Link>
            <Nav.Link href="#">DRIVE WITH US</Nav.Link>
            <Nav.Link href="#">BLOGS</Nav.Link>
            <Nav.Link href="#">CONTACT</Nav.Link>
          </Nav>
          <Nav></Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarSecion;
