import React from "react";
import Container from "react-bootstrap/Container";
import NavbarSecion from "../navbar/navbar";
import Button from "react-bootstrap/Button";
import "./home.scss";
import Car from "../../assets/car.png";
import Introducing from "../../assets/introducing.svg";
import Flight from "../../assets/flight.png";
import AirportRidesIcon from "../../assets/airport-rides-icon.svg";
import CityRidesIcon from "../../assets/city-rides-icon.svg";
import RentalsIcon from "../../assets/rentals-icon.svg";
import OutstationIcon from "../../assets/outstation-icon.svg";
import Users from "../../assets/users.svg";
import Kms from "../../assets/kms.svg";
import Co2 from "../../assets/co2.svg";
import Vehicles from "../../assets/vehicles.svg";
import WhychoooseImg1 from "../../assets/whychoose-img01.png";
import WhychoooseImg2 from "../../assets/whychooose-img02.png";
import SustainableIcon from "../../assets/sustainable-icon.svg";
import PickupIcon from "../../assets/pickup-icon.svg";
import PriceIcon from "../../assets/price-icon.svg";
import ComfortIcon from "../../assets/comfort-icon.svg";
import EcoIcon from "../../assets/eco-icon.svg";
import AirporttransferImg from "../../assets/airporttransfer-img01.png";
import CityrideImg from "../../assets/cityride-img02.png";
import OustationImg from "../../assets/oustation-img03.png";
import ReliabilityIcon from "../../assets/reliability-icon.svg";
import ComfortSafteyIcon from "../../assets/comfort-saftey-icon.svg";
import CosteffectiveIcon from "../../assets/costeffective-icon.svg";
import CarCorporate from "../../assets/car-corporate.png";
import Competitive from "../../assets/competitive.svg";
import Flexible from "../../assets/flexible.svg";
import Supportive from "../../assets/supportive.svg";
import Zero from "../../assets/zero.svg";
import DriverImg1 from "../../assets/driver-with-us-img1.png";
import ArrowRight from "../../assets/arrow-right.svg";
import FollowInstaIcon from "../../assets/follow-insta-icon.svg";
import ProfilePic from "../../assets/profile-pic.png";
import TestimonialMoreBtn from "../../assets/testimonial-more-btn.svg";
import AppScreen1 from "../../assets/app-screen01.png";
import AppScreen2 from "../../assets/app-screen2.png";
import Appstore from "../../assets/appstore-img.png";
import Googlplay from "../../assets/googleplay-img.png";
import CorporateBoxBg from "../../assets/corporate-box-bg.png";
import Hhmelogistic from "../../assets/ohmelogistic-img.png";
import Ohmauto from "../../assets/ohmauto-img.png";
import Ohmcharging from "../../assets/ohmcharging-img.png";
import LocationIcon from "../../assets/location-icon.svg";
import CallIcon from "../../assets/call-icon.svg";
import MailIcon from "../../assets/mail-icon.svg";
const Home = () => {
  return (
    <div>
      <NavbarSecion />
      <div className="hero-section position-relative">
        <Container className="mh-100vh position-relative">
          <div className="title-content">
            <div className="text1">Travel Green, Travel in Luxury</div>
            <div className="text2">Electric Rides</div>
            <div className="text3">from Airport to City to Rentals</div>
            <img src={Introducing} className="introducing" />
            <div className="car2">
              <img src={Car} className="mw-100" />
            </div>
          </div>
          <div className="hero-control">
            <a href="#" className="text-center">
              <img src={AirportRidesIcon} />
              <div>Airport Rides</div>
            </a>
            <a href="#" className="text-center">
              <img src={CityRidesIcon} />
              <div>City Rides</div>
            </a>
            <a href="#" className="text-center">
              <img src={RentalsIcon} />
              <div>Rentals</div>
            </a>
            <a href="#" className="text-center">
              <img src={OutstationIcon} />
              <div>Outstation</div>
            </a>
          </div>
        </Container>
        <div className="flight">
          <img src={Flight} />
        </div>
        <div className="car">
          <img src={Car} />
        </div>
      </div>
      <div className="our-journey py-5">
        <Container>
          <h2 className="title text-center pb-4">Our journey in numbers</h2>
          <p className="text-center">
            OHM E Logistics Pvt. Ltd. launches Eco Friendly (OHM Electric Cabs)
            for GMR approved Airport to City service, Vice versa and Intra-City
            Services. All our OHM Electric Cabs are Comfortable, Tidy, Fresh,
            Hygienic and Emission-free. There is a sizeable boot space for all
            your baggage's. Just relax and enjoy the music while we breeze to
            your location.
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="journey-box">
                <img src={Users} />
                <div>
                  <div className="text1">50000+</div>
                  <div className="text2">Happy Riders</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="journey-box">
                <img src={Kms} />
                <div>
                  <div className="text1">90 Lakh+</div>
                  <div className="text2">Kms Covered</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="journey-box">
                <img src={Co2} />
                <div>
                  <div className="text1">16.2 Lakh+</div>
                  <div className="text2">CO2 Saved</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="journey-box">
                <img src={Vehicles} />
                <div>
                  <div className="text1">1000+</div>
                  <div className="text2">Fleet Launched</div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="why-choose position-relative">
        <Container className="position-relative z-1">
          <div className="row">
            <div className="col">
              <img src={WhychoooseImg2} className="mw-100" />
            </div>
            <div className="col-md-9">
              <div className="text1">WHY CHOOSE US</div>
              <div className="text2 pb-3">
                Revolutionizing Urban Transport with Electric Cabs
              </div>
              <div className="row">
                <div className="col-10">
                  <div className="row g-2 justify-content-center">
                    <div className="col-md-4">
                      <div className="tag d-flex align-items-center">
                        <img src={SustainableIcon} className="me-2" />
                        <span>Sustainable</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="tag d-flex align-items-center">
                        <img src={PickupIcon} className="me-2" />
                        <span>On time pick and drop off</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="tag d-flex align-items-center">
                        <img src={PriceIcon} className="me-2" />
                        <span>No price surge</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="tag d-flex align-items-center">
                        <img src={ComfortIcon} className="me-2" />
                        <span>Comfort and Convenience</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="tag d-flex align-items-center">
                        <img src={EcoIcon} className="me-2" />
                        <span>Eco-Friendly Travel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row position-relative">
            <div className="col"></div>
            <div className="col-md-9">
              <div className="experience text-center">
                <div className="text3">10+ Years</div>
                <div className="text4">Leading E-Logistics Experience</div>
              </div>
              <img src={WhychoooseImg1} className="mw-100" />
            </div>
          </div>
        </Container>
      </div>
      <div className="eco-friendly">
        <div className="text1 text-center text-uppercase">
          Clean, Efficient, and Luxurious
        </div>
        <div className="text2 text-center pb-4">
          Eco-Friendly Travel with Electric Rides
        </div>
        <div className="row g-0">
          <div className="col-md-4 position-relative">
            <img src={AirporttransferImg} className="mw-100" />
            <div className="img-title position-absolute d-flex align-items-center justify-content-center">
              Airport Transfer
            </div>
          </div>
          <div className="col-md-4 position-relative">
            <img src={CityrideImg} className="mw-100" />
            <div className="img-title position-absolute d-flex align-items-center justify-content-center">
              City Rides
            </div>
          </div>
          <div className="col-md-4 position-relative">
            <img src={OustationImg} className="mw-100" />
            <div className="img-title position-absolute d-flex align-items-center justify-content-center">
              Rentals
            </div>
          </div>
        </div>
      </div>
      <div className="corporate-bookings">
        <div className="row align-items-end">
          <div className="col-md-6 text-end">
            <img src={CarCorporate} className="mw-100" />
          </div>
          <div className="col-md-4">
            <div className="text1">Corporate Bookings</div>
            <div className="text2 pb-5">
              Efficient Corporate Mobility Solutions
            </div>
            <p>
              Choose Ohm for employee transfers and events, ensuring ultimate
              comfort for your guests and employees.
            </p>
            <p>
              Experience seamless and reliable transportation with us. Duis in
              orci sit amet erat malesuada vestibulum. Aenean pharetra, nulla in
              hendrerit eleifend, leo libero mollis dolor, id blandit lectus
              diam in felis. Sed sed metus sit amet turpis iaculis ultrices nec
              a metus.
            </p>
            <div className="row mt-5">
              <div className="col-6 col-md-4 feature text-center">
                <img src={ReliabilityIcon} className="mb-3" />
                <div>Reliability</div>
              </div>
              <div className="col-6 col-md-4 feature text-center">
                <img src={ComfortSafteyIcon} className="mb-3" />
                <div>Comfort and Saftey</div>
              </div>
              <div className="col-6 col-md-4 feature text-center">
                <img src={CosteffectiveIcon} className="mb-3" />
                <div>Cost Effective</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drive-with-us">
        <Container>
          <div className="row mb-5">
            <div className="col-md-6">
              <div className="text1">DRIVE WITH US</div>
              <div className="text2 pb-3">
                Join Our Team of Dedicated Drivers Today
              </div>
              <p>
                We invite experienced and aspiring drivers to join our
                organization, where your skills and dedication will be
                recognized and rewarded
              </p>
              <div className="text3 pb-5">
                Drive with us and be part of a team that values your
                contribution and strives to help you succeed.
              </div>
              <div className="row">
                <div className="col-6 d-flex align-items-center mb-5">
                  <img src={Competitive} className="me-2" />
                  <span className="text4">Competitive pay and Bonus</span>
                </div>
                <div className="col-6 d-flex align-items-center mb-5">
                  <img src={Flexible} className="me-2" />
                  <span className="text4">Flexible working hours</span>
                </div>
                <div className="col-6 d-flex align-items-center mb-5">
                  <img src={Supportive} className="me-2" />
                  <span className="text4">24/7 Supportive Community</span>
                </div>
                <div className="col-6 d-flex align-items-center mb-5">
                  <img src={Zero} className="me-2" />
                  <span className="text4">Zero ownership cost Bonus</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src={DriverImg1} className="mw-100" />
            </div>
          </div>
          <div className="news-banner">
            <div className="banner1 row align-items-center justify-content-end">
              <div className="col-7 d-flex justify-content-between">
                <span>
                  Citroen Partners With OHM E Logistics To Deliver 1,000 e-C3
                  EVs
                </span>
                <a className="ms-5">
                  <img src={ArrowRight} />
                </a>
              </div>
            </div>
          </div>
          <div className="followus d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={FollowInstaIcon} />
              <div className="line mx-3"></div>
              <div>
                Follow us and stay up to date{" "}
                <a className="link">@ohm.electric.cabs</a>
              </div>
            </div>
            <Button className="btn">Follow Us</Button>
          </div>
        </Container>
      </div>
      <div className="testimonials position-relative">
        <Container className="position-relative z-1">
          <div className="row g-2">
            <div className="col-md-6">
              <div className="text1 text-center">TESTIMONIALS</div>
              <div className="text2 pb-5 text-center">Success Stories</div>
              <div className="frame-bg">
                <div className="message-sec d-flex mb-5 message-sec-right-pad">
                  <div className="message-box">
                    I went to OHM Hyderabad Showroom to enquire about electric
                    bikes for me. They clearly explained all variants and their
                    hospitality is very good. I have purchased an Evoqis Bike
                    and their service is also very good.
                    <div className="text-end">
                      Bhanu Prakash<br></br>
                      CEO, Airtel
                    </div>
                  </div>
                  <div className="ms-2">
                    <img src={ProfilePic} />
                  </div>
                </div>
                <div className="message-sec d-flex message-sec-left-pad">
                  <div className="me-2">
                    <img src={ProfilePic} />
                  </div>
                  <div className="message-box">
                    I went to OHM Hyderabad Showroom to enquire about electric
                    bikes for me. They clearly explained all variants and their
                    hospitality is very good. I have purchased an Evoqis Bike
                    and their service is also very good.
                    <div className="text-end">
                      Bhanu Prakash<br></br>
                      CEO, Airtel
                    </div>
                  </div>
                </div>
                <div className="more-btn">
                  <a>
                    <img src={TestimonialMoreBtn} className="mw-100" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text1 text-center">MOBILE APP</div>
              <div className="text2 pb-5 text-center">Market Place</div>
              <div className="app-bg position-relative">
                <img src={AppScreen1} className="img1" />
                <img src={AppScreen2} className="img2" />
                <div className="d-flex justify-content-center store-btns">
                  <a href="#">
                    <img src={Googlplay} />
                  </a>
                  <a href="#">
                    <img src={Appstore} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="corporates">
        <div className="text1 text-center">CORPORATES</div>
        <div className="text2 pb-3 text-center">OHM Group of companies</div>
        <div className="city-bg">
          <Container>
            <div className="row corporate-box-sec">
              <div className="col-md-4 text-center position-relative">
                <img src={CorporateBoxBg} className="mx-100" />
                <div className="corporate-img text-center">
                  <img src={Hhmelogistic} />
                </div>
                <div className="corporate-text text-center">
                  <div className="title">OHM</div>E Logistics Pvt. Ltd.
                </div>
              </div>
              <div className="col-md-4 text-center position-relative">
                <img src={CorporateBoxBg} className="mx-100" />
                <div className="corporate-img text-center">
                  <img src={Ohmauto} />
                </div>
                <div className="corporate-text text-center">
                  <div className="title">OHM</div>
                  Automotive Pvt. Ltd.
                </div>
              </div>
              <div className="col-md-4 text-center position-relative">
                <img src={CorporateBoxBg} className="mx-100" />
                <div className="corporate-img text-center">
                  <img src={Ohmcharging} />
                </div>
                <div className="corporate-text text-center">
                  <div className="title">OHM</div>
                  Charging Solution Pvt. Ltd.
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <footer>
        <Container>
          <div className="row sub-sec1">
            <div className="col-md-3 text-center">
              <div className="sub-icon  d-flex align-items-center justify-content-center mb-4">
                <img src={LocationIcon} />
              </div>
              H.O, 20B, ASCI Campus, Road No 5, Banjara Hills, Hyderabad -
              500034.
            </div>
            <div className="col-md-3 text-center">
              <div className="sub-icon d-flex align-items-center justify-content-center mb-4">
                <img src={CallIcon} />
              </div>
              Sales: +91 81430 33344 Service: +91 81430 33388
            </div>
            <div className="col-md-3 text-center">
              <div className="sub-icon d-flex align-items-center justify-content-center mb-4">
                <img src={MailIcon} />
              </div>
              <a href="#" className="d-block">
                sales@ohmautomotives.com
              </a>
              <a href="#" className="d-block">
                support@ohmautomotives.com
              </a>
            </div>
            <div className="col-md-3">
              <div className="pb-3">Subscribe our Newsletter</div>
              <div className="mb-3">
                <input
                  className="w-100 subscribe-input"
                  placeholder="Enter your email here"
                ></input>
              </div>
              <Button className="btn w-100">Subscribe</Button>
            </div>
          </div>
        </Container>
        <hr className="my-4"></hr>
        <Container>
          <div className="d-flex align-items-center justify-content-between py-3 copyright-text">
            <div>
              OHM E Logistics Pvt. Ltd. | Copyright © 2024. All Rights Reserved.
            </div>
            <div>
              <a href="#">Terms & Conditions</a> |{" "}
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Home;
