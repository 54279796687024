import React from "react";
import Home from "./components/home/home";
import './App.scss';

import {
  BrowserRouter,
  Routes,
  Redirect,
  Navigate,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div>
     <BrowserRouter>
       <Routes>
         <Route
           exact
           path="/"
           element={<Home/>}
         ></Route>
         {/* <Route
           exact
           path="/home"
           element={<Home/>}
         ></Route> */}
         
       </Routes>
     </BrowserRouter>
   </div>
  );
}

export default App;
